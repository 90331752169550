import classNames from 'classnames';
import Link from 'next/link';
import React from 'react';
import { useTheme } from '@/components/context/theme';
import styles from './styles.module.scss';
import Icon from '@/components/common/Icon';

interface Props {
  node: {
    ctaInternal: any;
    isSecondary: boolean;
    title: string;
    ctaExternal: any;
  };
}

const CTARenderer = ({ node }: Props): JSX.Element => {
  const theme = useTheme();

  if (node.ctaInternal) {
    return (
      <Link href={node.ctaInternal} legacyBehavior>
        <a
          className={classNames(
            styles.ctaLink,
            node.isSecondary ? styles.secondary : styles.primary,
            theme === 'light' && styles.light
          )}>
          <div className={styles.buttonContent}>{node.title}</div>
        </a>
      </Link>
    );
  }

  return (
    <Link href={node.ctaExternal} legacyBehavior>
      <a
        className={classNames(
          styles.ctaLink,
          node.isSecondary ? styles.secondary : styles.primary,
          theme === 'light' && styles.light
        )}
        target="_blank"
        rel="noreferrer">
        <div className={styles.buttonContent}>
          {node.title}
          <div className={styles.spacer}>
            <Icon type="arrowright" />
          </div>
        </div>
      </a>
    </Link>
  );
};

export default CTARenderer;
