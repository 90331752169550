import Img from 'next/image';
import Link from 'next/link';
import { useNextSanityImage } from 'next-sanity-image';
import React from 'react';

import TextListNumber from '@/components/bits/TextList/Number';
import { sanityClient } from '@/utils/sanity/sanity.server';

import TextCarousel from '../bits/TextCarousel';

interface InternalLinkRendererProps {
  mark: {
    href: string;
  };
  children?: any;
}

export const InternalLinkRenderer = ({
  mark,
  children,
}: InternalLinkRendererProps): JSX.Element => (
  <Link href={mark.href} passHref legacyBehavior>
    <a>{children}</a>
  </Link>
);

interface VideoEmbedRendererProps {
  node?: {
    url: string;
  };
}

export const VideoEmbedRenderer = ({ node }: VideoEmbedRendererProps): JSX.Element => {
  return (
    <div>
      <iframe
        title="video"
        src={node?.url}
        width="100%"
        height="auto"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen></iframe>
    </div>
  );
};

interface ListItemRendererProps {
  node?: {
    listItem: string;
  };
  index: number;
  children?: any;
}

export const ListItemRenderer = ({ node, index, children }: ListItemRendererProps): JSX.Element => {
  if (node?.listItem === 'number') {
    return (
      <li>
        <TextListNumber>{index + 1}</TextListNumber>
        <span>{children}</span>
      </li>
    );
  }

  return <li>{children}</li>;
};

interface ImageRendererProps {
  node: any;
  quality: number;
  alt: string;
}

export const ImageRenderer = ({ node, quality, alt }: ImageRendererProps): JSX.Element => {
  const imageProps: any = useNextSanityImage(sanityClient, node);

  return (
    <div className="imageRenderer">
      <div className="imageContainer">
        <Img
          className="image"
          quality={quality || 75}
          src={imageProps?.src}
          layout="fill"
          objectFit="contain" // 'cover'
          priority
          alt={alt || 'large image'}
        />
      </div>
    </div>
  );
};

interface TextCarouselRendererProps {
  node: {
    content: any;
  };
}

export const TextCarouselRenderer = ({ node }: TextCarouselRendererProps): JSX.Element => {
  return (
    <div className="textCarouselRenderer">
      <div className="textCarouselContainer">
        <TextCarousel blocks={node.content} />
      </div>
    </div>
  );
};

interface BlockRendererProps {
  node?: {
    style: string;
  };
  children: any;
}

export const BlockRenderer = ({ node, children }: BlockRendererProps): JSX.Element => {
  switch (node.style) {
    case 'h1':
      return <h1>{children}</h1>;
    case 'h2':
      return <h2>{children}</h2>;
    case 'h3':
      return <h3>{children}</h3>;
    case 'h4':
      return <h4>{children}</h4>;
    case 'blockquote':
      return <blockquote>{children}</blockquote>;
    case 'normal':
      return <p>{children}</p>;
    default:
      return <p>{children}</p>;
  }
};

const serializers = {
  listItem: ListItemRenderer,
  types: {
    textCarousel: TextCarouselRenderer,
    videoEmbed: VideoEmbedRenderer,
    figure: ImageRenderer,
    block: BlockRenderer,
  },
  marks: {
    internalLink: InternalLinkRenderer,
  },
};

export default serializers;
