import classnames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const CarouselButtonGroup = ({ className, children }: Props): JSX.Element => {
  return <div className={classnames(styles.buttonContainer, className)}>{children}</div>;
};

export default CarouselButtonGroup;
