import React from "react";
import DescriptionList from "@/components/common/article/DescriptionList";

interface Props {
  node: {
    descriptionList: any;
  }
}

const DescriptionListRenderer = ({ node }: Props): JSX.Element => {
  return (
    <DescriptionList items={node.descriptionList} />
  );
};

export default DescriptionListRenderer;
