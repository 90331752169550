import BlockContent from '@sanity/block-content-to-react';
import React from 'react';

import NumberTag from '@/components/icons/NumberTag';

import serializers from '../../../utils/serializers';
import styles from './styles.module.scss';

interface Props {
  index: number;
  text: any;
}

const TextCarouselSlide = ({ text, index }: Props): JSX.Element => {
  return (
    <div className={styles.textCarouselSlide}>
      <div className={styles.numberTag}>
        <NumberTag>{index}</NumberTag>
      </div>
      <BlockContent blocks={text} serializers={serializers} />
    </div>
  );
};

export default React.memo(TextCarouselSlide);
