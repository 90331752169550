import React from 'react';
import SimpleBlockContent from '@/components/bits/SimpleBlockContent/SimpleBlockContent';

import styles from '../../../styles.module.scss';

const TextGridRenderer = ({ node }: {
  node: {
    textGrid: any;
  };
}): JSX.Element => {
  return (
    <div className={styles.textGridRenderer}>
      {node.textGrid.map((text) => (
        <div key={text._key} className={styles.textBlock}>
          <SimpleBlockContent data={text.textBlock} />
        </div>
      ))}
    </div>
  );
};

export default TextGridRenderer;
