import React from 'react';
import { urlForImage } from '@/utils/sanity/sanity';

const ImageRenderer = ({ node }: any): JSX.Element => {
  return (
    <img className="rounded-lg w-full h-auto" src={urlForImage(node).width(800).url()} alt="" />
  );
};

export default React.memo(ImageRenderer);
