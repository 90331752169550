import Link from 'next/link';
import React from 'react';

const InternalLinkRenderer = (props: {
  mark: {
    href: string
  }
  children: React.ReactNode
}) => {
  const href = props.mark.href || null;

  return href ? (
    <Link href={props.mark.href} legacyBehavior>
      <a>{props.children}</a>
    </Link>
  ) : (
    <span>
      <a>{props.children}</a>
    </span>
  );
};

export default React.memo(InternalLinkRenderer);
