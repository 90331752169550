import React from 'react';

interface Props {
  children: any;
  node: {
    listItem: string;
  };
}

const ListItemRenderer = ({ children, node }: Props): JSX.Element => {
  return (
    <li className={`text-md ${node.listItem == 'number' ? 'list-decimal ml-5' : 'list-disc ml-5'}`}>
      {children}
    </li>
  );
};

export default ListItemRenderer;
