import Vimeo from '@u-wave/react-vimeo';
import React from 'react';

const Video = ({ url }: {
  url: string
}): JSX.Element => {
  return <Vimeo className="rounded-lg overflow-hidden" video={url} responsive width="100%" />;
};

export default React.memo(Video);
