import React from "react";

const ColorRenderer = ({ mark, children }: {
  mark: {
    color: string;
  };
  children: any;
}
): JSX.Element => {
  return <span style={{ color: mark.color }}>{children}</span>;
};

export default ColorRenderer;
