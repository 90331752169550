import React from 'react';
import Button from '@/components/common/Button';
import InternalLinkRenderer from '../../ArticlePortableText/Serializer/components/InternalLinkRenderer';
import CTARenderer from './CTARenderer';
import { urlForImage } from '@/utils/sanity/sanity';
import DatapointsRenderer from '../../ArticlePortableText/Serializer/components/DatapointsRenderer';

interface colorProps {
  children: any;
  mark: {
    alpha: number;
    rgb: any;
  };
}

const color = ({ mark, children }: colorProps): JSX.Element => {
  const { r, g, b } = mark.rgb;
  return <span style={{ color: `rgba(${r}, ${g}, ${b}, ${mark.alpha})` }}>{children}</span>;
};

interface BlockRendererProps {
  node: {
    style: string;
  };
  children: any;
}

const BlockRenderer = ({ node, children }: BlockRendererProps): JSX.Element => {
  switch (node.style) {
    case 'normal':
      return <p className={'text-md'}>{children}</p>;
    case 'bodySmall':
      return <p className={'text-sm'}>{children}</p>;
    case 'bodyLarge':
      return <p className={`text-lg`}>{children}</p>;
    case 'h4':
      return <h4>{children}</h4>;
    case 'h5':
      return <h5>{children}</h5>;
    default:
      return <p>{children}</p>;
  }
};

interface ListRendererProps {
  children: any;
}

const ListRenderer = ({ children }: ListRendererProps): JSX.Element => {
  return <ul className="text-md">{children}</ul>;
};

interface ListItemRendererProps {
  node: {
    listItem: string;
  };
  children: any;
}

const ListItemRenderer = ({ node, children }: ListItemRendererProps): JSX.Element => {
  switch (node.listItem) {
    case 'bullet':
      return <li className="list-disc ml-5">{children}</li>;
    case 'number':
      return <li className="list-decimal ml-5">{children}</li>;
    default:
      return <li>{children}</li>;
  }
};

interface ButtonRendererProps {
  node: any;
}

const ButtonRenderer = ({ node }: ButtonRendererProps): JSX.Element => {
  return (
    <p className="flex">
      <Button {...node} />
    </p>
  );
};

interface ImageRendererProps {
  node: any;
}

const ImageRenderer = ({ node }: ImageRendererProps): JSX.Element => {
  return <img className="rounded-lg" src={urlForImage(node).width(800).url()} alt="" />;
};

const serializers = {
  types: {
    block: BlockRenderer,
    cta: CTARenderer,
    button: ButtonRenderer,
    figure: ImageRenderer,
    datapoints: DatapointsRenderer,
  },
  marks: {
    internalLink: InternalLinkRenderer,
    color: color,
  },
  list: ListRenderer,
  listItem: ListItemRenderer,
};

export default serializers;
