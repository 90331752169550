import BlockContent from '@sanity/block-content-to-react';
import React from 'react';

import serializers from '@/components/utils/serializers';

import styles from './styles.module.scss';

interface Props {
  data: any;
}

const QuestionAnswers = ({ data }: Props): JSX.Element => {
  return (
    <div className={styles.questionAnswers}>
      <div className={styles.list}>
        {data?.map((entry) => {
          return (
            <div key={entry._key} className={styles.listItem}>
              <div className={styles.question}>
                <p>{entry.question}</p>
              </div>
              <div className={styles.answer}>
                <BlockContent blocks={entry.answer} serializers={serializers} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default QuestionAnswers;
