import React from "react";

const Datapoints = ({ datapoints }: {
  datapoints: [
    {
      number: number;
      caption: string;
    }
  ];
}): JSX.Element => {
  return (
    <dl className="flex flex-wrap gap-x-[2rem] md:gap-x-[4rem] gap-y-10 justify-center">
      {datapoints &&
        datapoints.map((d, i) => (
          <div key={i} className="flex flex-col items-center">
            <dt className="text-h2 md:text-h1">{d.number}</dt>
            <dd>{d.caption}</dd>
          </div>
        ))}
    </dl>
  );
};

export default Datapoints;
