import { useMediaQuery } from '@react-hook/media-query';
import React from 'react';

import styles from './styles.module.scss';

/**
 * Instead of a single "default" flexbox column layout,
 * we split up the items in two vertical columns.
 */
const DescriptionList = ({ items }: {
  items: any[]
}): JSX.Element => {
  const isPhone = useMediaQuery('(max-width: 768px)');

  if (!items) return null;

  const chunkSize = isPhone ? items.length / 2 : items.length / 3;
  const chunked = chunkArray(items, Math.round(chunkSize));

  return (
    <div className={styles.container}>
      <dl className={styles.descriptionList}>
        <div className={styles.content}>
          {chunked.map((chunk, i) => (
            <div key={i} className={styles.column}>
              {chunk.map((item) => (
                <div key={item._key} className={styles.descriptionListItem}>
                  <dt>{item.term}</dt>
                  <dd>{item.details}</dd>
                </div>
              ))}
            </div>
          ))}
        </div>
      </dl>
    </div>
  );
};

/**
 * Returns an array with arrays of the given size.
 *
 * @param myArray {Array} array to split
 * @param chunk_size {Integer} Size of every group
 */
function chunkArray(myArray, chunk_size): any[] {
  let index = 0;
  const arrayLength = myArray.length;
  const tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    const myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
}

export default React.memo(DescriptionList);
