import React from "react";
import QuestionAnswers from "@/components/bits/QuestionAnswers";

interface Props {
  node: {
    content: any;
  }
}

const QuestionAnswerRenderer = ({ node }: Props): JSX.Element => {
  return (
    <QuestionAnswers data={node.content} />
  );
};

export default QuestionAnswerRenderer;
