const accordionQuery = `
  ...,
  items[] {
    ...,
    content[] {
      ...,
      _type == 'imageBlock' => {
        ...,
        image[] {
          ...,
          "imageUrl": asset->url
        }
      }
    }
  }
`;

export default accordionQuery.replace(/ +/g, '');
