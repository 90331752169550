import React from 'react';

import Video from '@/components/bits/Video';

const VideoEmbedRenderer = (props: any): JSX.Element => {
  return (
    <Video url={props.node.url} />
  )
};

export default React.memo(VideoEmbedRenderer);
