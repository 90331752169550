import React from "react";
import Datapoints from "@/components/bits/Datapoints";

const DatapointsRenderer = ({ node }: {
  node: {
    datapoints: [{
      number: number;
      caption: string;
    }]
  }
}): JSX.Element => {
  return (
    <Datapoints datapoints={node.datapoints} />
  );
};

export default DatapointsRenderer;
