import React from 'react';

import TextCarousel from "@/components/bits/TextCarousel";

import styles from '../../../styles.module.scss';

const TextCarouselRenderer = (props: any) => {
  return (
    <div className={styles.textCarouselRenderer}>
      <div className={styles.textCarouselContainer}>
        <TextCarousel
          blocks={props.node.content}
        />
      </div>
    </div>
  );
}

export default React.memo(TextCarouselRenderer);
