import React from 'react';

const BlockRenderer = (props: {
  children: React.ReactNode,
  node: {
    style: string
  }
}): JSX.Element => {
  switch (props.node.style) {
    case 'h2':
      return <h2>{props.children}</h2>;
    case 'h3':
      return <h3>{props.children}</h3>;
    case 'h4':
      return <h4>{props.children}</h4>;
    case 'h5':
      return <h5>{props.children}</h5>;
    case 'blockquote':
      return <blockquote>{props.children}</blockquote>;
    case 'normal':
      return <p className="text-md">{props.children}</p>;
    case 'bodySmall':
      return <p className="text-sm">{props.children}</p>;
    case 'bodyLarge':
      return <p className="text-lg">{props.children}</p>;
    default:
      return <p className="text-md">{props.children}</p>;
  }
};

export default React.memo(BlockRenderer);
