import BlockContent from '@sanity/block-content-to-react';
import React from 'react';

import Serializer from './Serializer';

interface Props {
  data: any;
  className?: string | null;
}

function SimpleBlockContent({ data, className = null }: Props): JSX.Element {
  return (
    <BlockContent
      className={className}
      renderContainerOnSingleChild={true}
      blocks={data}
      serializers={Serializer}
    />
  );
}

export default React.memo(SimpleBlockContent);
