import createImageUrlBuilder from '@sanity/image-url';
import { createPreviewSubscriptionHook } from 'next-sanity';
import { ImageUrlBuilder } from 'next-sanity-image';

import { sanityConfig } from './config';

export const imageBuilder = createImageUrlBuilder(sanityConfig);

export const urlForImage = (source: string): ImageUrlBuilder =>
  imageBuilder.image(source).auto('format').fit('max');

export const usePreviewSubscription = createPreviewSubscriptionHook(sanityConfig);
