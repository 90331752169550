import React from 'react';

interface Props {
  children: any;
  mark: {
    href: string;
  };
}

const ExternalLinkRenderer = ({ children, mark }: Props): JSX.Element => {
  return (
    <a
      className="italic border-b border-green text-green hover:text-primary hover:border-primary"
      href={mark.href}
      target="_blank"
      rel="noreferrer noopener">
      {children}
    </a>
  );
};

export default ExternalLinkRenderer;
