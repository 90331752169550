import classnames from 'classnames';
import React, { ForwardedRef } from 'react';

import { LeftArrow, RightArrow } from './icons';
import styles from './styles.module.scss';

interface Props {
  direction: 'left' | 'right';
  className?: string;
  disabled?: boolean;
  onClick?: any;
}

const CarouselButton = React.forwardRef(
  (props: Props, ref: ForwardedRef<HTMLButtonElement>): JSX.Element => {
    return (
      <button
        ref={ref}
        className={classnames(styles.button, props.className)}
        aria-label={props.direction === 'left' ? 'Show previous' : 'Show next'}>
        {props.direction === 'left' ? <LeftArrow /> : <RightArrow />}
      </button>
    );
  }
);

CarouselButton.displayName = 'CarouselButton';

export default CarouselButton;
