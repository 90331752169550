import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import CarouselButton from '@/components/common/buttons/CarouselButton';
import CarouselButtonGroup from '@/components/common/buttons/CarouselButtonGroup';

import Slide from './Slide';
import styles from './styles.module.scss';

interface SwiperProps {
  params: any;
  navigation: any;
}

interface TextCarouselProps {
  blocks: [any];
}

const TextCarousel = ({ blocks }: TextCarouselProps): JSX.Element => {
  const navPrevButton = useRef<HTMLButtonElement>(null);
  const navNextButton = useRef<HTMLButtonElement>(null);

  const onInitSwiper = ({ params, navigation }: SwiperProps): void => {
    if (typeof params.navigation !== 'boolean') {
      params.navigation.prevEl = navPrevButton.current;
      params.navigation.nextEl = navNextButton.current;
      navigation.update();
    }
  };

  return (
    <div className={styles.textCarousel}>
      <Swiper onInit={onInitSwiper} grabCursor navigation slidesPerView="auto" speed={640}>
        {blocks.map((b, i) => {
          return (
            <SwiperSlide key={b._key}>
              <Slide text={b.text} index={i + 1} />
            </SwiperSlide>
          );
        })}
        <CarouselButtonGroup className={styles.buttonGroup}>
          <CarouselButton direction="left" ref={navPrevButton} />
          <CarouselButton direction="right" ref={navNextButton} />
        </CarouselButtonGroup>
      </Swiper>
    </div>
  );
};

export default TextCarousel;
