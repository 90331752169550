import React from 'react';

const LeftArrow = React.memo((props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={19}
      height={16}
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M18.286 7.404v1.191H2.23l6.415 6.563L7.82 16 .001 8l7.82-8 .823.842-6.415 6.562h16.057z"
        fill="#000"
      />
    </svg>
  );
});

LeftArrow.displayName = 'LeftArrow';

const RightArrow = React.memo((props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={19}
      height={16}
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M0 8.596V7.405h16.057L9.642.842 10.465 0l7.82 8-7.82 8-.823-.842 6.415-6.562H0z"
        fill="#000"
      />
    </svg>
  );
});
RightArrow.displayName = 'RightArrow';

export { LeftArrow, RightArrow };
