import classnames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
  theme?: 'primary' | 'inverted';
}

const NumberTag = ({ children, theme, className }: Props): JSX.Element => {
  return (
    <div
      className={classnames(styles.numberTag, className, theme === 'inverted' && styles.inverted)}>
      {children}
    </div>
  );
};

export default NumberTag;
