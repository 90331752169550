import React from 'react';

import styles from './styles.module.scss';

const Number = ({ children }: {
  children: React.ReactNode
}): JSX.Element => {
  return (
    <span className={styles.circleContainer}>
      <span className={styles.circle}>
        <span>{children}</span>
      </span>
    </span>
  );
};

export default React.memo(Number);
