import BlockRenderer from './components/BlockRenderer';
import ColorRenderer from './components/ColorRenderer';
import DatapointsRenderer from './components/DatapointsRenderer';
import DescriptionListRenderer from './components/DescriptionListRenderer';
import ExternalLinkRenderer from './components/ExternalLinkRenderer';
//import ImageGalleryRenderer from './components/ImageGalleryRenderer';
import ImageRenderer from './components/ImageRenderer';
import InternalLinkRenderer from './components/InternalLinkRenderer';
import ListItemRenderer from './components/ListItemRenderer';
import QuestionAnswerRenderer from './components/QuestionAnswerRenderer';
import TextCarouselRenderer from './components/TextCarouselRenderer';
import TextGridRenderer from './components/TextGridRenderer';
import VideoEmbedRenderer from './components/VideoEmbedRenderer';

const serializers = {
  listItem: ListItemRenderer,
  types: {
    datapoints: DatapointsRenderer,
    //imageGallery: ImageGalleryRenderer,
    textCarousel: TextCarouselRenderer,
    videoEmbed: VideoEmbedRenderer,
    figure: ImageRenderer,
    block: BlockRenderer,
    questionsAndAnswers: QuestionAnswerRenderer,
    descriptions: DescriptionListRenderer,
    textGrid: TextGridRenderer,
  },
  marks: {
    internalLink: InternalLinkRenderer,
    link: ExternalLinkRenderer,
    colorPicker: ColorRenderer,
  },
};

export default serializers;
